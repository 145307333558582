const defaultSurveyCSS = {
  navigationButton: "",
  page: {
    title: "page-title",
    description: "sv_page-description",
  },
};

const defaultSurveyDATA = {};

const defaultSurveyConfig = {
  defaultSurveyCSS,
  defaultSurveyDATA,
};

export default defaultSurveyConfig;
