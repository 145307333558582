import { Box, Container, Link, Typography } from "@mui/material";

export default function Footer() {
  return (
    <footer>
      <Container
        maxWidth="lg"
        sx={{ borderTop: "1px solid #e6e4e3", py: 3, my: 2 }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography sx={{ fontWeight: 700 }} color="primary">
              <Link
                sx={{ textDecoration: "none" }}
                href="mailto:admin@stratwayfind.com"
                target="_top"
              >
                Help
              </Link>
            </Typography>
          </Box>
          <Box>
            <Typography>© 2022 Strategic Wayfinding</Typography>
          </Box>
        </Box>
      </Container>
    </footer>
  );
}
