import { Shield } from "@mui/icons-material";
import { SliderThumb } from "@mui/material";
import React from "react";

interface ShieldThumbComponentProps extends React.HTMLAttributes<unknown> {}

export function ShieldThumbComponent(props: ShieldThumbComponentProps) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      <Shield />
      {children}
    </SliderThumb>
  );
}
