import {
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";

import { useUserAuth } from "../context/auth.context";
import { Controller, useForm } from "react-hook-form";

type LoginFormValues = {
  username: string;
  password: string;
};

export interface ILoginPageProps {}

const LoginPage: FC<ILoginPageProps> = (props) => {
  const { control, handleSubmit } = useForm<LoginFormValues>({
    defaultValues: { username: "", password: "" },
  });
  const { googleSignIn, userSignIn } = useUserAuth();
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    try {
      await userSignIn(data.username, data.password);
    } catch {
      alert("Error!");
    }
    setLoading(false);
  });

  return (
    <Container component="main" maxWidth="sm" sx={{ py: { xs: 1, md: 2 } }}>
      <Paper variant="outlined" sx={{ p: { xs: 2, md: 3 } }}>
        <Typography component="h2" variant="h4" align="center" gutterBottom>
          Welcome back
        </Typography>
        <Typography component="h5" variant="body1" align="center" gutterBottom>
          Get started finding your strategic direction.
        </Typography>
        <Grid container spacing={2} direction="column" sx={{ my: 1 }}>
          <Grid item xs={12}>
            <form onSubmit={onSubmit}>
              <Controller
                name="username"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label="Email"
                    variant="outlined"
                    type="email"
                    error={!!error}
                    helperText={error ? error.message : null}
                    fullWidth
                    sx={{ mb: 3 }}
                    {...field}
                  />
                )}
                rules={{ required: "Email required" }}
              />
              <Controller
                name="password"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label="Password"
                    variant="outlined"
                    type="password"
                    error={!!error}
                    helperText={error ? error.message : null}
                    fullWidth
                    sx={{ mb: 3 }}
                    {...field}
                  />
                )}
                rules={{ required: "Password required" }}
              />
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={loading}
                fullWidth
              >
                Login
              </Button>
            </form>
          </Grid>
          <Typography
            component="h5"
            variant="body1"
            align="center"
            gutterBottom
          >
            or
          </Typography>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={googleSignIn}
            >
              Log in with Google
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default LoginPage;
