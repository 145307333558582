import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Container,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import { doc, getDoc, Timestamp, updateDoc } from "firebase/firestore";
import { db } from "../config/firebase";
import { useParams } from "react-router-dom";
import * as Survey from "survey-react";

import SurveyComponent from "../components/Survey";
import defaultSurveyConfig from "../config/survey";
import IResponse from "../types/response.type";
import ISurveyData from "../types/survey.type";

// Survey theming
import MuiAdapter from "../components/MuiAdapter";

export interface IApplicationProps {}

const ResponsePage: React.FunctionComponent<IApplicationProps> = (props) => {
  const [response, setResponse] = useState<IResponse | undefined>();
  const [prevResponse, setPrevResponse] = useState<any>({});
  const [survey, setSurvey] = useState<ISurveyData | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  // Params
  const { responseId } = useParams();

  // Firebase
  const responseRef = doc(db, "responses", responseId!);

  const getData = async (): Promise<void> => {
    try {
      const response = await getDoc(responseRef);
      const responseObj = {
        id: response.id,
        ...response.data(),
      } as IResponse;

      const survey = await getDoc(doc(db, "surveys", responseObj.surveyId));
      const surveyObj = { id: survey.id, ...survey.data() } as ISurveyData;

      setSurvey(surveyObj);
      setResponse(responseObj);

      let prevResponseData = window.localStorage.getItem(responseId!) || null;
      if (prevResponseData) {
        setPrevResponse(JSON.parse(prevResponseData));
      }

      setLoading(false);
    } catch (e) {
      // Could not find the response or associated survey
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    Survey.StylesManager.applyTheme("bootstrapmaterial");
    getData();
  }, []);

  if (loading)
    return (
      <Container
        component="main"
        maxWidth="md"
        sx={{ py: 8, textAlign: "center" }}
      >
        <CircularProgress />
        <Typography variant="subtitle2" sx={{ mt: 2 }}>
          Just a moment...
        </Typography>
      </Container>
    );

  if (!survey || !response)
    return (
      <Container component="main" maxWidth="md">
        <Paper variant="outlined" sx={{ my: 3, p: { xs: 2, md: 3 } }}>
          <Typography component="h1" variant="h4" gutterBottom>
            There's nothing here.
          </Typography>
          <Typography component="p" gutterBottom>
            We're sorry, but nothing exists here. Please confirm the URL with
            your facilitator.
          </Typography>
        </Paper>
      </Container>
    );

  const surveyJSON = new Survey.Model(survey.data);
  surveyJSON.onAfterRenderQuestion.add(function (survey: any, options: any) {
    // Create our flexbox div
    var div = document.createElement("div");
    div.className = "sv_min-max_container";

    // Get the existing elements
    var responseBtns = options.htmlElement.querySelector(
      "div:nth-child(2) > div.btn-group"
    );
    var minWord = options.htmlElement.querySelector(
      "span.sv_q_rating_min_text"
    );
    var maxWord = options.htmlElement.querySelector(
      "span.sv_q_rating_max_text"
    );

    if (responseBtns == null) return;

    // Rebuild a new flexbox below the rating buttons
    div.appendChild(minWord);
    div.appendChild(maxWord);
    responseBtns.parentNode.appendChild(div);
    responseBtns.className = "sv_rating-buttons btn-group";
  });

  return (
    <Container component="main" maxWidth="md">
      <Paper variant="outlined" sx={{ my: 3, p: { xs: 2, md: 3 } }}>
        <Typography component="h1" variant="h4" gutterBottom>
          {survey.data.title}
        </Typography>
        <Typography component="h3" variant="h6" gutterBottom>
          {survey.data.description}
        </Typography>

        {response.complete ? (
          <Typography component="p" gutterBottom>
            Thank you for submitting your evaluation.
            <br />
            Please talk to your facilitator if you have any questions.
          </Typography>
        ) : (
          <>
            {!survey.published ? (
              <Typography component="p" gutterBottom>
                This survey has not yet been published and is not accepting
                evaluations.
                <br />
                Please talk to your facilitator if you have any questions.
              </Typography>
            ) : (
              <Typography component="p" gutterBottom>
                Welcome {response && response.name}, please rate your level of
                agreement with the following statements and attach a confidence
                score to each. You may optionally add comments to contribute to
                a larger group discussion.
              </Typography>
            )}
          </>
        )}
      </Paper>
      {!response.complete && survey.published && (
        <Card variant="outlined" sx={{ my: 2 }}>
          <CardContent>
            <MuiAdapter>
              <SurveyComponent
                css={defaultSurveyConfig.defaultSurveyCSS}
                survey={surveyJSON}
                data={prevResponse}
                onPartialSend={async (responseData: any) => {
                  // Save in local storage
                  let data = responseData.data;
                  data.pageNo = responseData.currentPageNo;
                  window.localStorage.setItem(
                    responseId!,
                    JSON.stringify(data)
                  );
                }}
                onComplete={async (responseData: any) => {
                  console.log(responseData.data);

                  // Update Firebase Response doc with complete
                  // Note: this will trigger a CF to update survey counts
                  await updateDoc(responseRef, {
                    data: responseData.data,
                    complete: true,
                    completedAt: Timestamp.now(),
                  });
                }}
              />
            </MuiAdapter>
          </CardContent>
        </Card>
      )}
    </Container>
  );
};

export default ResponsePage;
