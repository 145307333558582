import React, { useEffect, useState } from "react";
import { ReactComponent as Shield } from "./shield-fill.svg";
import { ReactComponent as Comment } from "./comment-35.svg";
import { ReactComponent as WayfinderLogo } from "./logo_blank.svg";
import { ReactComponent as Group } from "./group-hybrid.svg";
import { useSurveyResults } from "../context/results.context";

// Styles
import "./chartStyles.css";
import { nameToInitials } from "../utils/utils";

interface ICanvasProps {
  initWidth: number;
  initHeight: number;
  data?: Array<DataPoint>;
  score?: {
    x: number;
    y: number;
  };
}

type DataPoint = {
  id: string;
  name: string;
  x: number;
  y: number;
  comment?: string;
  fill: string;
};

const Canvas = ({ initWidth, initHeight, data, score }: ICanvasProps) => {
  const [groupedData, setGroupedData] = useState<Array<Array<DataPoint>>>([]);
  const [heightMultiplier, setHeightMultiplier] = useState(1);
  const { selectedParticipant, selectParticipant } = useSurveyResults();

  const LINE_Y = 30;
  const LABEL_Y = 48;
  const NUMBERS_Y = 30;
  const CIRCLE_Y = 30;

  const makeAxis = () => {
    return (
      <g className="linechart_axis">
        <line
          x1={7}
          x2={7}
          y1={LINE_Y * heightMultiplier - 4}
          y2={LINE_Y * heightMultiplier + 4}
        />
        <line
          x1={8}
          y1={LINE_Y * heightMultiplier}
          x2={initWidth - 8}
          y2={LINE_Y * heightMultiplier}
        />
        <line
          x1={initWidth - 7}
          x2={initWidth - 7}
          y1={LINE_Y * heightMultiplier - 4}
          y2={LINE_Y * heightMultiplier + 4}
        />
      </g>
    );
  };

  const makeAxisLabels = () => {
    return (
      <g className="labels x-labels">
        <text x={45} y={LINE_Y * heightMultiplier + LABEL_Y}>
          Completely Disagree
        </text>
        <text x={initWidth - 45} y={LINE_Y * heightMultiplier + LABEL_Y}>
          Completely Agree
        </text>
        {Array.from({ length: 11 }, (_, i) => (
          <text
            key={`axisLabel-${i}`}
            className="labels x-numbers"
            x={(i / 10) * (initWidth - 16) + 8}
            y={LINE_Y * heightMultiplier + NUMBERS_Y}
            textAnchor="middle"
          >
            {i}
          </text>
        ))}
      </g>
    );
  };

  const getFillColor = (confidence: number) => {
    return `hsl(0,1%,${(11 - confidence) * 0.75 * 10}%)`;
  };

  interface IconProps {
    x: number;
    y: number;
    opacity: number;
  }

  const GroupScoreIcon = (props: IconProps) => {
    // TODO: Move this to a function and use with other data
    // Rescale to min(6) and max(10)
    let x = props.opacity;
    let opacity = (6 + ((x - 0) * (10 - 6)) / (10 - 0)) / 10;

    return (
      <>
        <WayfinderLogo
          height={26}
          width={26}
          x={props.x}
          y={props.y}
          opacity={`${opacity}`}
        />
        <Group height={16} width={16} x={props.x + 5} y={props.y + 5} />
      </>
    );
  };

  /**
   * This function draws our user circles on the graph for data point, stacked by confidence level
   * @returns An SVG Group for each circle (user) data point
   */
  const drawData = () => {
    return (
      <>
        {groupedData.map((data, i) => (
          <g key={i} className="data">
            {data?.map((e, j) => {
              return (
                <g
                  key={`datapoint-${j}`}
                  className="data-point"
                  onClick={() => selectParticipant(e.id)}
                >
                  {e.id === "group" ? (
                    <GroupScoreIcon
                      x={(e.x / 10) * (initWidth - 16) - 5}
                      y={CIRCLE_Y * heightMultiplier - j * CIRCLE_Y - 13}
                      opacity={e.y}
                    />
                  ) : (
                    <circle
                      cx={(e.x / 10) * (initWidth - 16) + 8}
                      cy={CIRCLE_Y * heightMultiplier - j * CIRCLE_Y}
                      r={12}
                      fill={
                        selectedParticipant !== e.id
                          ? getFillColor(e.y)
                          : e.fill
                      }
                    />
                  )}
                  {e.id !== "group" && (
                    <text
                      x={(e.x / 10) * (initWidth - 16) + 8}
                      y={CIRCLE_Y * heightMultiplier - j * CIRCLE_Y}
                      textAnchor="middle"
                      dy=".3em"
                    >
                      {nameToInitials(e.name)}
                    </text>
                  )}

                  <Shield
                    x={(e.x / 10) * (initWidth - 16) + 8 + 3}
                    y={CIRCLE_Y * heightMultiplier - j * CIRCLE_Y + 3}
                    className="shield"
                    height={12}
                    width={12}
                  />
                  <text
                    x={(e.x / 10) * (initWidth - 16) + 8}
                    y={CIRCLE_Y * heightMultiplier - j * CIRCLE_Y}
                    textAnchor="middle"
                    className="confidence-text"
                    dx="1.17em"
                    dy="1.475em"
                  >
                    {e.y}
                  </text>
                  {e.comment && (
                    <Comment
                      className="comment"
                      x={(e.x / 10) * (initWidth - 16) + 8 + 3}
                      y={CIRCLE_Y * heightMultiplier - j * CIRCLE_Y - 15}
                      height={11}
                      width={11}
                      title={e.comment}
                    />
                  )}
                </g>
              );
            })}
          </g>
        ))}
      </>
    );
  };

  useEffect(() => {
    let initialGroupedData = Array<Array<DataPoint>>();

    // Group our data by agreement level, sort by confidence level
    for (let i = 0; i <= 10; i++) {
      let circles = data!.filter((e) => e.x === i).sort((a, b) => b.y - a.y);
      initialGroupedData.push(circles);

      // Increase our height multiplier if needed
      if (circles.length > heightMultiplier) {
        setHeightMultiplier(circles.length);
      }
    }

    setGroupedData(initialGroupedData);
  }, [data, heightMultiplier]);

  return (
    <svg
      style={{ overflow: "visible", padding: "1em" }}
      viewBox={`0 0 ${initWidth} ${initHeight + CIRCLE_Y * heightMultiplier}`}
    >
      {makeAxis()}
      {makeAxisLabels()}
      {drawData()}
    </svg>
  );
};

Canvas.defaultProps = {
  initWidth: 600,
  initHeight: 80,
};

export default Canvas;
