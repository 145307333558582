import React, { useEffect, useState } from "react";
import {
  DocumentData,
  FirestoreError,
  QuerySnapshot,
} from "firebase/firestore";

import { useNavigate, useParams } from "react-router-dom";

import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { SurveyResultsContextProvider } from "../context/results.context";
import ResultQuestion from "../components/ResultQuestion";
import IResponse from "../types/response.type";
import { SurveyDataService } from "../services/survey.service";
import { formatRelative } from "date-fns";
import { useUserAuth } from "../context/auth.context";
import ISurvey from "../types/survey.type";
import ManageEvaluators from "../components/ManageEvaluators";

export interface ISurveyResultsPageProps {}

const SurveyResultsPage: React.FunctionComponent<ISurveyResultsPageProps> = (
  props
) => {
  const [survey, setSurvey] = useState<ISurvey | undefined>();
  const [responses, setResponses] = useState<IResponse[] | undefined>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const surveyService = new SurveyDataService();
  const { surveyId } = useParams();
  const { user } = useUserAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubSurvey = surveyService.streamSurvey(
      surveyId!,
      (doc: DocumentData) => {
        setSurvey(doc.data());
      },
      (error: FirestoreError) => console.error(error)
    );

    const unsubResponses = surveyService.streamResponses(
      surveyId!,
      user!.uid,
      (snap: QuerySnapshot<DocumentData>) => {
        const responses = snap.docs.map((doc) => doc.data() as IResponse);
        setResponses(responses);
      },
      (error: FirestoreError) => {
        console.error(error);
      }
    );

    setLoading(false);

    return () => {
      unsubSurvey();
      unsubResponses();
    };
  }, []);

  if (loading || !survey) return <CircularProgress />;

  return (
    <SurveyResultsContextProvider>
      <Container component="main" maxWidth="lg" sx={{ my: 3 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Stack sx={{ minWidth: "50%" }}>
            <Typography component="h1" variant="h3" gutterBottom>
              {survey!.title}
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              {survey!.data.description}
            </Typography>
          </Stack>

          {survey.published ? (
            <Stack alignItems={"flex-end"} gap={2}>
              <Chip
                label={`Published ${formatRelative(
                  survey.publishedAt!.toDate(),
                  Date.now()
                )}`}
                color="success"
                size="medium"
              />
              <Button
                variant="contained"
                onClick={() => navigate(`/dashboard/edit/${survey.id}`)}
              >
                Edit Survey
              </Button>
            </Stack>
          ) : (
            <Chip
              label={`Draft started ${formatRelative(
                survey.createdAt.toDate(),
                Date.now()
              )}`}
              variant="outlined"
              size="medium"
            />
          )}
        </Box>

        <Container component="main" maxWidth="md" sx={{ my: 3 }}>
          <Grid container spacing={3} sx={{ mb: 6 }}>
            <Grid item xs={12}>
              <ManageEvaluators
                survey={survey}
                responses={responses}
                surveyService={surveyService}
              />
            </Grid>
          </Grid>
        </Container>

        <Typography component="h2" variant="h5" gutterBottom sx={{ mt: 5 }}>
          Current Findings
        </Typography>
        {survey!.data?.pages.map((page: any, i: number) => {
          let completedResponses = responses?.filter(
            (response) => response.complete
          );
          return (
            <ResultQuestion
              key={i}
              questionNumber={i + 1}
              questionName={page.name}
              questionText={page.description}
              responses={completedResponses}
              surveyId={survey.id}
              scores={survey.scores}
            />
          );
        })}
      </Container>
    </SurveyResultsContextProvider>
  );
};

export default SurveyResultsPage;
