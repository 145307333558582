import { createContext, FC, useContext, useState } from "react";

export interface ISurveyResultsProps {
  selectedParticipant?: string;
  selectParticipant?: any;
}

const SurveyResultsContext = createContext({} as ISurveyResultsProps);

export const SurveyResultsContextProvider: FC<ISurveyResultsProps> = ({
  children,
}) => {
  const [selectedParticipant, setSelectedParticipant] = useState<string>("");

  function selectParticipant(id: string) {
    // TODO: Select a full participant type/model
    setSelectedParticipant(id);
  }

  return (
    <SurveyResultsContext.Provider
      value={{ selectedParticipant, selectParticipant }}
    >
      {children}
    </SurveyResultsContext.Provider>
  );
};

export function useSurveyResults() {
  return useContext(SurveyResultsContext);
}
