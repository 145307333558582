import {
  AddCircle,
  PeopleAlt,
  Delete as DeleteIcon,
  Person as PersonIcon,
  AlternateEmail as EmailIcon,
} from "@mui/icons-material";
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { SurveyFormData } from "../types/survey.type";

interface IAddEvaluators {}

const AddEvaluators: FC<IAddEvaluators> = () => {
  const { control, formState } = useFormContext<SurveyFormData>();

  const { fields, insert, remove } = useFieldArray<SurveyFormData>({
    name: "participants",
    control,
  });

  return (
    <Paper variant="outlined" sx={{ p: { xs: 2, md: 3 } }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <PeopleAlt />
        <Typography variant="h5">Evaluators</Typography>
      </Stack>
      <Grid container spacing={2} direction="column" sx={{ my: 1 }}>
        {fields.map((field, index) => (
          <Grid
            container
            item
            key={field.id}
            direction="row"
            alignItems="center"
            spacing={2}
            xs={12}
            marginBottom={3}
          >
            <Grid item xs={10} display="inline-flex" alignItems="center">
              <PersonIcon sx={{ marginRight: 2 }} />
              <Controller
                name={`participants.${index}.name` as const}
                control={control}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    placeholder="First and last name"
                    fullWidth
                    error={Boolean(formState.errors.participants?.[index].name)}
                    helperText={
                      formState.errors.participants?.[index].name?.message
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={10} display="inline-flex" alignItems="center">
              <EmailIcon sx={{ marginRight: 2 }} />
              <Controller
                name={`participants.${index}.email` as const}
                control={control}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    placeholder="Email address"
                    fullWidth
                    error={Boolean(
                      formState.errors.participants?.[index].email
                    )}
                    helperText={
                      formState.errors.participants?.[index].email?.message
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            {index !== 0 && (
              <Grid item xs={2}>
                <IconButton onClick={() => remove(index)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        ))}
        <Grid item xs={6} textAlign="center">
          <Button
            variant="text"
            color="primary"
            startIcon={<AddCircle color="success" />}
            onClick={() =>
              insert(fields.length + 1, {
                id: fields.length + 1,
                name: "",
              })
            }
          >
            Add An Evaluator
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AddEvaluators;
