import React, { useEffect, useState } from "react";
import {
  DocumentData,
  FirestoreError,
  QuerySnapshot,
} from "firebase/firestore";
import { ReactComponent as WayfinderLogo } from "../components/logo_blank.svg";
import { ReactComponent as Group } from "../components/group-hybrid.svg";

import { useParams } from "react-router-dom";

import {
  Box,
  Chip,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Avatar,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { SurveyResultsContextProvider } from "../context/results.context";
import IResponse from "../types/response.type";
import { SurveyDataService } from "../services/survey.service";
import { format } from "date-fns";
import { useUserAuth } from "../context/auth.context";
import ISurvey from "../types/survey.type";
import { stringAvatar } from "../utils/utils";
import ExportQuestion from "../components/ExportQuestion";

export interface IExportResultsPageProps {}

const ExportResultsPage: React.FunctionComponent<IExportResultsPageProps> = (
  props
) => {
  const [survey, setSurvey] = useState<ISurvey | undefined>();
  const [responses, setResponses] = useState<IResponse[] | undefined>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [filterIndividuals, setFilterIndividuals] = useState<boolean>(false);

  const surveyService = new SurveyDataService();
  const { surveyId } = useParams();
  const { user } = useUserAuth();

  useEffect(() => {
    const unsubSurvey = surveyService.streamSurvey(
      surveyId!,
      (doc: DocumentData) => {
        setSurvey(doc.data());
      },
      (error: FirestoreError) => console.error(error)
    );

    const unsubResponses = surveyService.streamResponses(
      surveyId!,
      user!.uid,
      (snap: QuerySnapshot<DocumentData>) => {
        const responses = snap.docs.map((doc) => doc.data() as IResponse);
        setResponses(responses);
      },
      (error: FirestoreError) => {
        console.error(error);
      }
    );

    setLoading(false);

    return () => {
      unsubSurvey();
      unsubResponses();
    };
  }, []);

  const handleFilterIndividuals = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterIndividuals(e.target.checked);
  };

  if (loading || !survey) return <CircularProgress />;

  // Check to see if this survey has been group scored
  let showGroupLegend = false;
  for (let e of Object.entries(survey.scores)) {
    if (e[1] != null) {
      showGroupLegend = true;
    }
  }

  return (
    <SurveyResultsContextProvider>
      <Container component="main" maxWidth="lg" sx={{ my: 3 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Stack sx={{ minWidth: "50%" }}>
            <Typography component="h1" variant="h3" gutterBottom>
              {survey!.title}
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              {survey!.data.description}
            </Typography>
          </Stack>

          {survey.published ? (
            <Stack alignItems={"flex-end"} gap={2}>
              <Chip
                label={`Published ${format(
                  survey.publishedAt!.toDate(),
                  "EEE, MMM do, yyyy - h:mm a"
                )}`}
                color="success"
                size="medium"
              />
            </Stack>
          ) : (
            <Chip
              label={`Draft started ${format(
                survey.createdAt.toDate(),
                "EEE, MMM do, yyyy - h:mm a"
              )}`}
              variant="outlined"
              size="medium"
            />
          )}
        </Box>

        <Container component="main" maxWidth="md" sx={{ my: 3 }}>
          <Grid container spacing={3} sx={{ mb: 6 }}>
            <Grid item xs={12}>
              <Paper variant="outlined" sx={{ p: { xs: 2, md: 3 } }}>
                <Typography variant="h6" gutterBottom>
                  Evaluators
                </Typography>
                <List>
                  {responses?.map((response, i: number) => (
                    <Box key={i}>
                      <ListItem sx={{ paddingRight: 10 }}>
                        <ListItemAvatar>
                          <Avatar
                            alt={response.name}
                            {...stringAvatar(response.name)}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          secondary={
                            response.email ? `(${response.email})` : null
                          }
                        >
                          {response.name}{" "}
                          {survey.published ? (
                            response.complete ? (
                              <Chip
                                label={`Completed ${format(
                                  response.completedAt!.toDate(),
                                  "MMM dd, yyyy . h:mm a"
                                )}`}
                                color="success"
                                size="small"
                                sx={{ ml: 1 }}
                              />
                            ) : (
                              <Chip
                                label="Pending"
                                color="info"
                                size="small"
                                sx={{ ml: 1 }}
                              />
                            )
                          ) : null}
                        </ListItemText>
                      </ListItem>
                    </Box>
                  ))}
                  {showGroupLegend ? (
                    <Box key="group">
                      <ListItem sx={{ paddingRight: 10 }}>
                        <ListItemAvatar>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            <WayfinderLogo
                              height={40}
                              width={40}
                              style={{ position: "absolute" }}
                            />
                            <Group
                              height={24}
                              width={24}
                              style={{ position: "absolute", margin: "8px" }}
                            />
                          </div>
                        </ListItemAvatar>
                        <ListItemText>{"Group Consensus Score"}</ListItemText>
                      </ListItem>
                    </Box>
                  ) : null}
                </List>
              </Paper>
            </Grid>
          </Grid>
        </Container>

        <Typography component="h2" variant="h5" gutterBottom sx={{ mt: 5 }}>
          Evaluation Responses
        </Typography>
        <FormGroup className="no-print">
          <FormControlLabel
            control={
              <Checkbox
                checked={filterIndividuals}
                onChange={handleFilterIndividuals}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Hide individual responses"
          />
        </FormGroup>

        {survey!.data?.pages.map((page: any, i: number) => {
          // Filter to only completed responses
          let completedResponses = responses?.filter(
            (response) => response.complete
          );

          // Filter out responses if not selected
          if (filterIndividuals) {
            completedResponses = responses?.filter(
              (response) => response.name === "Group"
            );
          }

          return (
            <>
              <ExportQuestion
                key={i}
                questionNumber={i + 1}
                questionName={page.name}
                questionText={page.description}
                responses={completedResponses}
                surveyId={survey.id}
                scores={survey.scores}
              />
              {/* <div className="pagebreak"></div> */}
            </>
          );
        })}
      </Container>
    </SurveyResultsContextProvider>
  );
};

export default ExportResultsPage;
