import React from "react";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";

import { ReactComponent as WayfinderLogo } from "./components/logo_full.svg";

// Pages
import HomePage from "./pages/HomePage";
import ProtectedRoute from "./components/ProtectedRoute";
import { useUserAuth } from "./context/auth.context";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  AppBar,
  Avatar,
  Box,
  Container,
  CssBaseline,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  Breadcrumbs,
} from "@mui/material";
import { AccountCircle as LoginIcon } from "@mui/icons-material";
import DashboardPage from "./pages/DashboardPage";
import EditSurveyPage from "./pages/EditSurveyPage";
import ResponsePage from "./pages/ResponsePage";
import { stringAvatar } from "./utils/utils";
import Footer from "./components/Footer";
import CreateSurveyPage from "./pages/CreateSurveyPage";
import SurveyResultsPage from "./pages/SurveyResultsPage";
import { LinkRouter } from "./components/LinkRouter";
import LoginPage from "./pages/LoginPage";
import ExportResultsPage from "./pages/ExportResultsPage";

export interface IApplicationProps {}

const Application: React.FunctionComponent<IApplicationProps> = (props) => {
  const { user, logOut } = useUserAuth();
  const navigate = useNavigate();

  // Breadcrumbs Config
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => {
    // TODO: Come up with a better method to filter out the ugly surveyId
    if (x.length < 10) return x;

    return null;
  });
  const breadcrumbNameMap: { [key: string]: string } = {
    "/dashboard": "Dashboard",
    "/dashboard/results": "Survey Results",
    "/dashboard/create": "Create Survey",
    "/dashboard/edit": "Edit Survey",
  };

  // MUI Theming
  const theme = createTheme({
    typography: {
      fontFamily: ["Work Sans"].join(","),
      h1: {
        fontWeight: 700,
      },
      h2: {
        fontWeight: 700,
      },
      h3: {
        fontWeight: 700,
      },
    },
    palette: {
      primary: {
        main: "#4c5d78",
      },
      secondary: {
        main: "#8c4559",
      },
      background: {
        default: "#f9f8f8",
      },
    },
  });

  // App Bar Menu
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = async () => {
    setAnchorElUser(null);

    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
          backgroundColor: "#fff",
          mb: 5,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <LinkRouter
              to="/"
              variant="h6"
              underline="none"
              noWrap
              sx={{
                mr: 2,
                flexGrow: 1,
                color: "white",
                "&:hover": {
                  color: "white",
                  textDecoration: "none",
                },
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <WayfinderLogo height={40} />{" "}
                <Typography
                  variant="h5"
                  component="h1"
                  color="#595959"
                  sx={{
                    ml: 1,
                    fontFamily: "Arial",
                    fontWeight: "bold",
                    letterSpacing: -1,
                  }}
                >
                  Strategic Wayfinding
                </Typography>
              </Box>
            </LinkRouter>

            <Box sx={{ flexGrow: 0 }}>
              {user ? (
                <>
                  <Tooltip title="Open settings">
                    <IconButton sx={{ p: 0 }} onClick={handleOpenUserMenu}>
                      <Avatar
                        alt={user.displayName!}
                        src={user.photoURL!}
                        {...stringAvatar(user.displayName!)}
                      />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <MenuItem onClick={handleLogout}>
                      <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <Tooltip title="Login">
                  <IconButton sx={{ p: 0 }} onClick={() => navigate("/login")}>
                    <LoginIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      {!user || pathnames[0] === "r" ? null : (
        <Container maxWidth="lg">
          <Breadcrumbs aria-label="breadcrumb">
            <LinkRouter underline="hover" color="inherit" to="/">
              Home
            </LinkRouter>
            {pathnames.map((val, index) => {
              const last = index === pathnames.length - 1;
              const to = `/${pathnames.slice(0, index + 1).join("/")}`;

              return last ? (
                <Typography color="text.primary" key={to}>
                  {breadcrumbNameMap[to]}
                </Typography>
              ) : (
                <LinkRouter underline="hover" color="inherit" to={to} key={to}>
                  {breadcrumbNameMap[to]}
                </LinkRouter>
              );
            })}
          </Breadcrumbs>
        </Container>
      )}

      <Routes>
        <Route
          path="/"
          element={user ? <Navigate to="/dashboard" /> : <HomePage />}
        />
        <Route
          path="/login"
          element={user ? <Navigate to="/dashboard" /> : <LoginPage />}
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <DashboardPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/create"
          element={
            <ProtectedRoute>
              <CreateSurveyPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/edit/:surveyId"
          element={
            <ProtectedRoute>
              <EditSurveyPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/results/:surveyId"
          element={
            <ProtectedRoute>
              <SurveyResultsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/export/:surveyId"
          element={
            <ProtectedRoute>
              <ExportResultsPage />
            </ProtectedRoute>
          }
        />
        <Route path="/r/:responseId" element={<ResponsePage />} />
      </Routes>

      <Footer />
    </ThemeProvider>
  );
};

export default Application;
