import {
  AddCircle,
  Delete as DeleteIcon,
  IosShare as ShareIcon,
  Send as SendIcon,
  Link as LinkIcon,
  Person as PersonIcon,
  AlternateEmail as EmailIcon,
} from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import IResponse from "../types/response.type";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { v1 as uuidv1 } from "uuid";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { AlertDialog } from "./AlertDialog";
import { SurveyDataService } from "../services/survey.service";
import { Timestamp } from "firebase/firestore";
import { stringAvatar } from "../utils/utils";
import { useUserAuth } from "../context/auth.context";
import ISurvey from "../types/survey.type";
import { LinkRouter } from "./LinkRouter";
import { functions } from "../config/firebase";
import { httpsCallable } from "firebase/functions";

type FormValuesParticipant = {
  name: string;
  email?: string;
};

const schema = yup
  .object({
    name: yup
      .string()
      .max(80, "Name must be less than 80 characters")
      .required("Please enter full name"),
    email: yup
      .string()
      .max(250, "Email must be less than 250 characters")
      .email("Please enter a valid email"),
  })
  .required();

interface ISnackbar {
  open: boolean;
  message: string;
  severity: "success" | "error" | "warning" | "info";
}

interface IManageEvaluators {
  survey: ISurvey;
  responses: IResponse[] | undefined;
  surveyService: SurveyDataService;
}

const ManageEvaluators: FC<IManageEvaluators> = ({
  survey,
  responses,
  surveyService,
}) => {
  const [snackbar, setSnackbar] = useState<ISnackbar>();
  const [deleteDialog, setDeleteDialog] = useState<IResponse>();
  const [shareDialog, setShareDialog] = useState<IResponse>();

  const { user } = useUserAuth();

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    setSnackbar({ open: false, message: "", severity: "success" });
  };

  const {
    handleSubmit: handleSubmitParticipant,
    control: controlParticipant,
    reset: resetParticipant,
    formState,
  } = useForm<FormValuesParticipant>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSendEmail = () => {
    const shareEmail = httpsCallable(functions, "shareEmail");

    let sender = user!.displayName ?? user!.email!;
    let email = shareDialog?.email;
    let url = `${process.env.REACT_APP_BASE_URL}/r/${shareDialog?.id}`;

    shareEmail({
      sender,
      email,
      url,
    })
      .then((result) => {
        console.log(result);

        // Show success snackbar
        setSnackbar({
          open: true,
          message: `Email sent to ${email}!`,
          severity: "success",
        });

        // Close the dialog
        setShareDialog(undefined);
      })
      .catch((e) => {
        console.error(e);

        // Show error snackbar
        setSnackbar({
          open: true,
          message: `Email failed to send to ${email}!`,
          severity: "error",
        });

        // Close the dialog
        setShareDialog(undefined);
      });
  };

  const onSubmitParticipant: SubmitHandler<FormValuesParticipant> = async (
    data
  ) => {
    // Create a new Response object
    const response: IResponse = {
      id: uuidv1(),
      name: data.name,
      complete: false,
      surveyId: survey.id,
      createdAt: Timestamp.now(),
      createdBy: user!.uid,
    };

    if (data.email) {
      response.email = data.email;
    }

    // Add new Response to Firebase
    await surveyService.addResponse(response);

    // Refresh participants
    resetParticipant();

    // Build and show a snackbar
    const snack: ISnackbar = {
      open: true,
      message: "Evaluator successfully added to this Survey",
      severity: "success",
    };

    setSnackbar(snack);
  };

  return (
    <Paper variant="outlined" sx={{ p: { xs: 2, md: 3 } }}>
      <Typography variant="h6" gutterBottom>
        Evaluators
      </Typography>
      {survey.published && (
        <Alert severity="info" sx={{ mb: 2 }}>
          <AlertTitle>Share this survey</AlertTitle>This survey is published.
          Share it with your evaluators using the Share buttons below.
        </Alert>
      )}
      <List>
        {responses?.map((response, i: number) => (
          <Box key={i}>
            <ListItem sx={{ paddingRight: 10 }}>
              <ListItemAvatar>
                <Avatar alt={response.name} {...stringAvatar(response.name)} />
              </ListItemAvatar>
              <ListItemText>
                <LinkRouter to={`/r/${response.id}`} underline="none">
                  {response.name}{" "}
                  {response.email ? `(${response.email})` : null}
                </LinkRouter>
                {survey.published ? (
                  response.complete ? (
                    <Chip
                      label="Complete"
                      color="success"
                      size="small"
                      sx={{ ml: 1 }}
                    />
                  ) : (
                    <Chip
                      label="Pending"
                      color="info"
                      size="small"
                      sx={{ ml: 1 }}
                    />
                  )
                ) : null}
              </ListItemText>
              <ListItemSecondaryAction>
                <Tooltip title="Share Evaluation">
                  <IconButton
                    aria-label="share"
                    onClick={() => setShareDialog(response)}
                    sx={{ ml: 2 }}
                  >
                    <ShareIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Delete Evaluator">
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => setDeleteDialog(response)}
                    sx={{ ml: 1 }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          </Box>
        ))}
      </List>

      <form onSubmit={handleSubmitParticipant(onSubmitParticipant)}>
        <Typography variant="h6" mt={2}>
          Add Evaluator
        </Typography>
        <Grid container spacing={2} direction="column" sx={{ mb: 1 }}>
          <Grid
            container
            item
            direction="row"
            spacing={2}
            xs={12}
            alignItems="center"
            sx={{ mt: 2 }}
          >
            <Grid
              item
              xs={12}
              sm={10.5}
              display="inline-flex"
              alignItems="center"
            >
              <PersonIcon sx={{ marginRight: 2 }} />
              <Controller
                name="name"
                control={controlParticipant}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    fullWidth
                    placeholder="Evaluator Name"
                    error={Boolean(formState.errors.name?.message)}
                    helperText={formState.errors.name?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={10.5}
              display="inline-flex"
              alignItems="center"
            >
              <EmailIcon sx={{ marginRight: 2 }} />
              <Controller
                name="email"
                control={controlParticipant}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    fullWidth
                    placeholder="Evaluator Email"
                    error={Boolean(formState.errors.email?.message)}
                    helperText={formState.errors.email?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item xs={6} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddCircle />}
              type="submit"
              disabled={!formState.isDirty}
            >
              Add Evaluator
            </Button>
          </Grid>
        </Grid>
      </form>

      <Snackbar
        open={snackbar?.open}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>

      <AlertDialog
        title="Delete this Evaluator?"
        text="This action cannot be undone and all Evaluator data (including scoring and comments) will be lost forever."
        confirmButton="Delete"
        open={deleteDialog !== undefined && deleteDialog !== null}
        onClose={async (val) => {
          // Close the dialog
          setDeleteDialog(undefined);

          // If user accepted, remove the response
          if (val) {
            await surveyService.removeResponse(deleteDialog!);
          }
        }}
      />

      <Dialog
        onClose={() => setShareDialog(undefined)}
        open={shareDialog !== undefined && shareDialog !== null}
      >
        <DialogTitle>Share Evaluation</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Copy this unique URL to share this evaluation...
          </DialogContentText>
          <Typography
            variant="h6"
            gutterBottom
          >{`${process.env.REACT_APP_BASE_URL}/r/${shareDialog?.id}`}</Typography>
          <DialogActions>
            <CopyToClipboard
              text={`${process.env.REACT_APP_BASE_URL}/r/${shareDialog?.id}`}
              onCopy={() =>
                setSnackbar({
                  open: true,
                  message: "Response URL copied to clipboard!",
                  severity: "success",
                })
              }
            >
              <Button
                variant="contained"
                color="secondary"
                startIcon={<LinkIcon />}
              >
                Copy URL to clipboard
              </Button>
            </CopyToClipboard>
            {shareDialog?.email && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<SendIcon />}
                onClick={() => onSendEmail()}
              >
                Send Email
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Paper>
  );
};

export default ManageEvaluators;
