import {
  AddCircle,
  Chat as ChatIcon,
  Delete as DeleteIcon,
  DragHandle,
} from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Button,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FC } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { SurveyFormData } from "../types/survey.type";

interface IManageQuestions {
  hasResponses?: boolean;
}

const ManageQuestions: FC<IManageQuestions> = ({ hasResponses }) => {
  const { control, formState } = useFormContext<SurveyFormData>();

  const { fields, insert, move, remove } = useFieldArray<SurveyFormData>({
    name: "questions",
    control,
  });

  const handleDrag = (result: DropResult) => {
    const { source, destination } = result;

    if (destination) {
      move(source.index, destination.index);
    }
  };

  return (
    <Paper variant="outlined" sx={{ p: { xs: 2, md: 3 } }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <ChatIcon />
        <Typography variant="h5">Statements</Typography>
      </Stack>

      {hasResponses && (
        <Alert severity="warning" sx={{ mt: 1, mb: 2 }}>
          <AlertTitle>Responses Completed</AlertTitle>This survey already has
          completed responses. Editing statements now will affect survey and
          evaluation results.
        </Alert>
      )}

      <DragDropContext onDragEnd={handleDrag}>
        <Droppable droppableId="statement-items">
          {(provided, snapshot) => (
            <Grid
              container
              spacing={2}
              direction="column"
              sx={{ my: 1 }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {fields.map((field, index) => (
                <Draggable
                  key={`test[${index}]`}
                  draggableId={`item-${index}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <Grid
                      container
                      item
                      key={field.id}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      direction="row"
                      alignItems="center"
                      spacing={2}
                      xs={12}
                      marginBottom={1}
                    >
                      <Grid item xs={0.5}>
                        <Typography>{index + 1}.</Typography>
                      </Grid>
                      <Grid item xs={9.5}>
                        <Controller
                          name={`questions.${index}.text` as const}
                          control={control}
                          rules={{ maxLength: 280 }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              placeholder="New statement"
                              fullWidth
                              multiline
                              error={Boolean(
                                formState.errors.questions?.[index].text
                              )}
                              helperText={
                                formState.errors.questions?.[index].text
                                  ?.message
                              }
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        sx={{ display: { xs: "none", sm: "block" } }}
                      >
                        <IconButton {...provided.dragHandleProps}>
                          <DragHandle />
                        </IconButton>
                      </Grid>
                      {index !== 0 && (
                        <Grid item xs={1}>
                          <IconButton onClick={() => remove(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Draggable>
              ))}

              {provided.placeholder}

              <Grid item xs={6} textAlign="center">
                <Button
                  variant="text"
                  color="primary"
                  startIcon={<AddCircle color="success" />}
                  onClick={() =>
                    insert(fields.length + 1, {
                      text: "",
                    })
                  }
                >
                  Add A Statement
                </Button>
              </Grid>
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
    </Paper>
  );
};

export default ManageQuestions;
