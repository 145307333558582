import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { FC, useRef } from "react";
import { ReactComponent as WayfinderLogo } from "../components/logo_full.svg";
import { ReactComponent as CirclesImage } from "../components/circles.svg";

import { useUserAuth } from "../context/auth.context";

export interface IHomePageProps {}

const HomePage: FC<IHomePageProps> = (props) => {
  const learnRef = useRef<null | HTMLDivElement>(null);

  const { googleSignIn } = useUserAuth();

  const scrollToLearn = () => {
    if (learnRef.current) {
      learnRef.current.scrollIntoView({ behavior: "smooth" })!;
    }
  };

  return (
    <>
      {/* Hero section */}
      <Box sx={{ pt: 8, pb: 8 }}>
        <Container component="section" maxWidth="lg">
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "2fr 55%",
            }}
          >
            <Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
                <WayfinderLogo height={64} />{" "}
                <Typography
                  variant="h4"
                  component="h1"
                  color="#595959"
                  sx={{
                    ml: 1,
                    fontFamily: "Arial",
                    fontWeight: "bold",
                    letterSpacing: -1,
                  }}
                >
                  Strategic Wayfinding
                </Typography>
              </Box>
              <Typography
                component="h1"
                variant="h4"
                color="text.primary"
                gutterBottom
              >
                Explore key questions.
                <br />
                Achieve alignment.
                <br />
                Set an organizational course.
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                component="p"
                gutterBottom
              >
                Quickly and easily get to the heart of strategic decision making
                by surveying teams, groups, and commitees of all shapes and
                sizes.
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                sx={{ alignItems: "center", mt: 4 }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={googleSignIn}
                >
                  Get started
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  onClick={scrollToLearn}
                >
                  Learn more
                </Button>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                sx={{ alignItems: "center", mt: 4 }}
              >
                <Chip label="Beta" color="success" size="small" />
                <Typography variant="subtitle2">
                  Version 0.4.5 has just released.
                </Typography>
              </Stack>
            </Box>
            <Box>
              <CirclesImage height="480px" />
            </Box>
          </Box>
        </Container>
      </Box>
      {/* End Hero section */}

      {/* Next section */}
      <Box sx={{ pt: 8, pb: 8, backgroundColor: "#e9e9ec" }} ref={learnRef}>
        <Container component="section" maxWidth="lg">
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
                <Typography gutterBottom>
                  "This process identifies areas of misalignment, leans into
                  them, and addresses them. Values discovery happens through an
                  exploration of tensions.""
                </Typography>
                <Typography
                  align="right"
                  component="div"
                  variant="caption"
                  sx={{ fontWeight: "bold" }}
                >
                  - Steven Myhill-Jones
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h3" gutterBottom>
                The Method
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                1. Articulate key questions
              </Typography>
              <Typography gutterBottom sx={{ mb: 2 }}>
                Gather key quesetions to be asked and turn them into statements.
                The answers we arrive at will have a profound impact on our
                business direction and success.
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                2. Distill key questions
              </Typography>
              <Typography gutterBottom sx={{ mb: 2 }}>
                Distill things down to only the questions that need correct
                answers; questions we can't afford to be wrong about.
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                3. Individual quantification
              </Typography>
              <Typography gutterBottom sx={{ mb: 2 }}>
                Run an exploration with participants who possess key knowledge
                or will be instrumental in interpreting and implementing
                organization strategy.
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                4. Visualize and discuss responses
              </Typography>
              <Typography gutterBottom sx={{ mb: 2 }}>
                Compare and contrast individuals' initial positions. Identify
                and flag topics where there is not yet alignment and will
                require deeper dives.
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                5. Arrive at group consensus
              </Typography>
              <Typography gutterBottom sx={{ mb: 2 }}>
                The goal is to arrive at a common understanding about what we
                confidently hold to be true and the things which embody
                uncertainty.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box sx={{ pt: 8, pb: 8 }}>
        <Container
          component="section"
          maxWidth="lg"
          sx={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography variant="h3" gutterBottom>
            Ready to drive your organization forward?
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={googleSignIn}
            sx={{ mt: 3 }}
          >
            Get started for free
          </Button>
        </Container>
      </Box>
    </>
  );
};

export default HomePage;
