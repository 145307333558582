import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { useTheme } from "@mui/material";

export interface IMuiAdapter {}

const MuiAdapter: FC<IMuiAdapter> = ({ children }) => {
  const theme = useTheme();

  if (!theme.palette) {
    return <>{children}</>;
  }

  return (
    <>
      <Helmet>
        <style type="text/css">{`
          .page-title {
            font-size: 1.15rem;
          }
          
          .sv_page-description {
            margin: 0;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 400;
            font-size: 1.5rem;
            line-height: 1.334;
            letter-spacing: 0em;
            margin-bottom: 0.35em;
            text-align: center;
          }

          .sv_main.sv_main.sv_bootstrapmaterial_css .btn-default.active { }

          .sv_min-max_container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }

          .sv_rating-buttons {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
          }
          
          .card-footer {
            border-top: none;
            padding: 0.5rem 0.5rem;
          }

          .btn-primary {
            background-color: ${theme.palette.primary.main} !important;
          }

          .sv_main.sv_main.sv_bootstrapmaterial_css .btn-default.active {
            background-color: ${theme.palette.primary.main};
          }
      `}</style>
      </Helmet>
      {children}
    </>
  );
};

export default MuiAdapter;
