import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useUserAuth } from '../context/auth.context';

export interface IProtectedRouteProps {}

const ProtectedRoute: FC<IProtectedRouteProps> = ({ children }) => {
  const { user } = useUserAuth();

  if (!user) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
}

export default ProtectedRoute;
