import {
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  increment,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import * as Survey from "survey-react";
import { v1 as uuidv1 } from "uuid";
import { db } from "../config/firebase";
import IResponse from "../types/response.type";
import ISurvey, {
  SurveyFormData,
  IParticipant,
  IQuestion,
  SurveyFormQuestion,
} from "../types/survey.type";

export class SurveyDataService {
  surveysRef: CollectionReference<DocumentData> = collection(db, "surveys");
  responsesRef: CollectionReference<DocumentData> = collection(db, "responses");

  async getAll(userId: string) {
    let surveys = [] as Array<ISurvey>;

    try {
      const surveysQuery = query(
        this.surveysRef,
        where("createdBy", "==", userId)
      );

      const snap = await getDocs(surveysQuery);

      snap.docs.forEach((doc) => {
        surveys.push({ ...(doc.data() as ISurvey) });
      });
    } catch (e) {
      console.error(e);
    }

    return surveys;
  }

  streamSurveysForUser(userId: string, snapshot: any, error: any) {
    const surveysQuery = query(
      this.surveysRef,
      where("createdBy", "==", userId),
      orderBy("createdAt")
    );
    return onSnapshot(surveysQuery, snapshot, error);
  }

  async getSurvey(surveyId: string) {
    try {
      const surveySnap = await getDoc(doc(db, "surveys", surveyId));

      return surveySnap.data() as ISurvey;
    } catch (e) {
      console.error(e);
    }
  }

  streamResponses(surveyId: string, userId: string, snapshot: any, error: any) {
    const responsesQuery = query(
      this.responsesRef,
      where("createdBy", "==", userId),
      where("surveyId", "==", surveyId)
    );
    return onSnapshot(responsesQuery, snapshot, error);
  }

  streamSurvey(surveyId: string, snapshot: any, error: any) {
    return onSnapshot(doc(db, "surveys", surveyId), snapshot, error);
  }

  async addSurvey(formData: SurveyFormData, userId: string) {
    // Generate a unique id for our survey
    let surveyId = uuidv1();

    // Create SurveyJS data with settings
    let surveyData = new Survey.Model();
    surveyData.title = formData.title;
    surveyData.description = formData.description;
    surveyData.showTitle = false;
    surveyData.sendResultOnPageNext = true;
    surveyData.showQuestionNumbers = "off";
    surveyData.completedHtml = "<h3>Survey finished. Thank you.<h3>";

    // Add pages for every question
    formData.questions.forEach((question, index) => {
      // Create a page for the question
      let qNum = index + 1;
      let page = new Survey.PageModel("q" + qNum);

      let pageJSON = CreateQuestionJSON(qNum, question.text);
      page.fromJSON(pageJSON);

      // Add page to survey
      surveyData.addPage(page);
    });

    // Create our survey model
    const survey: ISurvey = {
      id: surveyId,
      title: formData.title,
      data: surveyData.toJSON(),
      scores: {},
      createdBy: userId,
      published: formData.published,
      createdAt: Timestamp.now(),
      countCompleted: 0,
      countPending: formData.participants.length,
    };

    if (survey.published) {
      survey.publishedAt = Timestamp.now();
    }

    // Add participants
    formData.participants.forEach(async (p, i) => {
      const participant: IParticipant = {
        id: uuidv1(),
        name: p.name,
        email: p.email,
      };

      // Save a response object to Firestore
      await setDoc(doc(db, "responses", participant.id), {
        ...participant,
        createdAt: Timestamp.now(),
        createdBy: userId,
        surveyId: surveyId,
      });
    });

    // Save to Firebase
    await setDoc(doc(db, "surveys", surveyId), survey);

    return surveyId;
  }

  async duplicateSurvey(surveyId: string, userId: string) {
    const original = await this.getSurvey(surveyId);

    if (original) {
      // Generate a unique id for our survey
      let dupSurveyId = uuidv1();

      // Create our duplicate survey model
      const duplicate: ISurvey = {
        id: dupSurveyId,
        title: "Copy of " + original.title,
        data: original.data,
        scores: {},
        createdBy: userId,
        createdAt: Timestamp.now(),
        published: false,
        countCompleted: 0,
        countPending: original.countCompleted + original.countPending,
      };

      await setDoc(doc(db, "surveys", dupSurveyId), duplicate);

      // Get all responses
      const responsesQuery = query(
        this.responsesRef,
        where("createdBy", "==", userId),
        where("surveyId", "==", surveyId)
      );

      const responses = await getDocs(responsesQuery);

      responses.forEach(async (participant) => {
        // Duplicate participant
        const dupParticipant: IParticipant = {
          id: uuidv1(),
          name: participant.data().name,
          email: participant.data().email,
        };

        // Save a new response object to Firestore
        await setDoc(doc(db, "responses", dupParticipant.id), {
          ...dupParticipant,
          createdAt: Timestamp.now(),
          createdBy: userId,
          surveyId: dupSurveyId,
        });
      });
    }
  }

  async editSurvey(
    surveyId: string,
    title: string,
    description: string,
    questions: Array<SurveyFormQuestion>
  ) {
    try {
      const updatedQuestions: IQuestion[] = questions.map(
        (q: SurveyFormQuestion, i: number) => {
          return { name: "", title: "", description: q.text, elements: [] };
        }
      );

      await this.updateQuestions(surveyId, updatedQuestions);

      return await updateDoc(doc(db, "surveys", surveyId), {
        title: title,
        "data.description": description,
        "data.title": title,
      });
    } catch (e) {
      console.error(e);
    }
  }

  setGroupScore(
    surveyId: string,
    qNum: number,
    agree: number,
    confidence: number,
    comment: string
  ) {
    const agreeKey = `scores.q${qNum}_agree`;
    const confKey = `scores.q${qNum}_confidence`;
    const commentKey = `scores.q${qNum}_comment`;

    return updateDoc(doc(db, "surveys", surveyId), {
      [agreeKey]: agree,
      [confKey]: confidence,
      [commentKey]: comment,
    });
  }

  removeGroupScore(
    surveyId: string,
    qNum: number,
  ) {
    const agreeKey = `scores.q${qNum}_agree`;
    const confKey = `scores.q${qNum}_confidence`;
    const commentKey = `scores.q${qNum}_comment`;

    return updateDoc(doc(db, "surveys", surveyId), {
      [agreeKey]: null,
      [confKey]: null,
      [commentKey]: null,
    });
  }

  publishSurvey(surveyId: string) {
    // Switch the Survey to published
    return updateDoc(doc(db, "surveys", surveyId), {
      published: true,
      publishedAt: Timestamp.now(),
    });
  }

  removeSurvey(surveyId: string) {
    // Delete the Survey
    return deleteDoc(doc(db, "surveys", surveyId));
  }

  // TODO: Move to this function within ManageEvaluators
  async addResponse(response: IResponse) {
    try {
      console.log('Response fields', response);
      await setDoc(doc(db, "responses", response.id), response);

      return updateDoc(doc(db, "surveys", response.surveyId), {
        countPending: increment(1),
      });
    } catch (e) {
      console.error(e);
    }
  }

  async removeResponse(response: IResponse) {
    try {
      await deleteDoc(doc(db, "responses", response.id));

      const updatedCount = response.complete
        ? {
            countCompleted: increment(-1),
          }
        : { countPending: increment(-1) };

      return updateDoc(doc(db, "surveys", response.surveyId), updatedCount);
    } catch (e) {
      console.error(e);
    }
  }

  async updateQuestions(surveyId: string, questions: Array<IQuestion>) {
    const survey = await this.getSurvey(surveyId);
    const updatedQuestions: IQuestion[] = [];

    if (survey) {
      // Add pages for every question
      questions.forEach((question, index) => {
        // Create a page for the question
        let qNum = index + 1;

        // Create a page for the question
        let page = CreateQuestionJSON(qNum, question.description);

        // Add page to survey
        updatedQuestions.push(page);
      });
    }

    await updateDoc(doc(db, "surveys", surveyId), {
      "data.pages": updatedQuestions!,
    });
  }
}

const CreateQuestionJSON = (qNum: number, qText: string) => {
  return {
    name: `q${qNum}`,
    title: `Question ${qNum}`,
    description: qText,
    elements: [
      {
        type: "rating",
        name: `q${qNum}_agree`,
        title: "Agreement:",
        isRequired: true,
        rateMin: 0,
        rateMax: 10,
        minRateDescription: "Completely Disagree",
        maxRateDescription: "Completely Agree",
      },
      {
        type: "rating",
        name: `q${qNum}_confidence`,
        title: "Level of confidence:",
        isRequired: true,
        rateMin: 0,
        rateMax: 10,
        minRateDescription: "Zero Confidence",
        maxRateDescription: "Absolute Confidence",
      },
      {
        type: "comment",
        name: "Comment(s)",
        valueName: `q${qNum}_comment`,
        maxLength: 800,
        rows: 5,
      },
    ],
  };
};
