import React from "react";
import * as Survey from "survey-react";

export interface ISurveyProps {
  css: any;
  survey: Survey.SurveyModel;
  data: any;
  onPartialSend: (survey: any) => void;
  onComplete: (survey: any) => void;
}

const SurveyComponent: React.FunctionComponent<ISurveyProps> = (props) => {
  const { css, survey, data, onPartialSend, onComplete } = props;

  return (
    <Survey.Survey
      css={css}
      model={survey}
      data={data}
      onPartialSend={onPartialSend}
      onComplete={onComplete}
    />
  );
};

export default SurveyComponent;
