const productDevTemplate = {
  defaultValues: {
    title: "Deciding on product features",
    description: "This sample can be used to prioritize product feature development. Each feature under consideration will need its own survey.",
    published: false,
    questions: [
      { text: "This feature will strengthen our position in the market." },
      { text: "This feature is applicable to a broad (greater than 50%) swath of our customers, not just a few." },
      { text: "This feature increases the stickiness of our product within our customers' organizations." },
      { text: "This feature is likely to increase net new sales of our product." },
      { text: "This feature is technically feasible to implement." },
      { text: "This feature presents an opportunity to better position our product against our competitors." },
    ],
    participants: [{ id: 1, name: "", email: "" }],
  },
}

const marketEntryTemplate = {
  defaultValues: {
    title: "Entering a new market",
    description: "This sample can be used to determine whether it's a wise decision to enter a new market.",
    published: false,
    questions: [
      { text: "This market presents an opportunity for us to grow our business." },
      { text: "We have the expertise - or the ability to get it - necessary to compete in this market." },
      { text: "The addressable market is large enough to justify the necessary investment." },
      { text: "We have the ability to compete with current incumbents in the market." },
      { text: "This is an advantageous time to enter this market." },
    ],
    participants: [{ id: 1, name: "", email: "" }],
  },
}

const startupFundingTemplate = {
  defaultValues: {
    title: "Funding a startup",
    description: "This sample can be used to help determine if you should make an investment in a startup.",
    published: false,
    questions: [
      { text: "We love the founding team." },
      { text: "The target market presents a strong opportunity." },
      { text: "The company has a differentiated offering for the target market." },
      { text: "The team has the expertise necessary to compete." },
      { text: "This product and team has the ability to build an enduring business." },
      { text: "The funding amount fits within our investment framework/approach." },
      { text: "This company has the potential to provide the returns we expect to see." },
      { text: "The valuation is reasonable." },
    ],
    participants: [{ id: 1, name: "", email: "" }],
  },
}

const jobEvaluationTemplate = {
  defaultValues: {
    title: "Evaluating a job candidate",
    description: "This sample can be used as a starting point for evaluating and ensuring internal alignment on potential job candidates.",
    published: false,
    questions: [
      { text: "This candidate would be a good cultural fit for our organization." },
      { text: "This candidate demonstrates a strong sense of teamwork." },
      { text: "This candidate demonstrates strong communication skills." },
      { text: "This candidate demonstrates a willingness to learn." },
      { text: "This candidate demonstrates a high level of self-motivation." },
      { text: "Overall, this candidate would make us a stronger team." },
      { text: "We should make an offer to this candidate to join our organization." },
    ],
    participants: [{ id: 1, name: "", email: "" }],
  },
}

const projectBidAnalysisTemplate = {
  defaultValues: {
    title: "Analysing a potential bid",
    description: "Sample statements to help assess a potential project we're trying to determine if we ought bid on. ",
    published: false,
    questions: [
      { text: "We’ve identified one or more mandatory requirements with the RFP/RFI that embodies a potential no-go for submitting a bid." },
      { text: "We view this a strategic account given factors like aligned requirements with our product direction/roadmap, the customer being in a desired target jurisdiction, or the special/high-profile nature of the organization." },
      { text: "Setting aside geographic location, the solicitation is from a desirable client (appearing to be intelligent thought leaders at a well-run organization with clear requirements and treats vendors fairly)." },
      { text: "This opportunity is in our proven sweet spot in terms desirable jurisdiction size, type of entity, project domain, and other factors." },
      { text: "This opportunity is a match with the strengths of our products (and has limited susceptibility to our constraints and sources of challenge) out-of-the-box or with routine/upgradeable JTI 2.0 type configuration." },
      { text: "We see a path to making money on this project that we think is compatible with the customer’s budget and buying intent." },
      { text: "It should be workable to have the required skillsets and numbers of people available to complete the project within acceptable/negotiable project delivery timelines." },
      { text: "This deal ought yield a strong positive return (through direct profitability, or via follow-on work through establishing a strategic beachhead in a desirable new jurisdiction)." },
      { text: "This appears to be a blue ocean opportunity where there is not a high-likelihood incumbent." },
      { text: "We do not envision significant barriers to entry in terms of certifications, location/language challenges, nearby references that could impair us." },
    ],
    participants: [{ id: 1, name: "", email: "" }],
  },
}

const surveyTemplates = {
  productDevTemplate,
  marketEntryTemplate,
  startupFundingTemplate,
  jobEvaluationTemplate,
  projectBidAnalysisTemplate,
}

export default surveyTemplates;