import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  User,
} from "firebase/auth";
import { createContext, FC, useContext, useEffect, useState } from "react";
import { auth } from "../config/firebase";

type FBUser = User | null;

export interface IUserAuthProps {
  user?: FBUser;
  googleSignIn?: any; // TODO: Figure out what type this should be
  userSignUp?: any;
  userSignIn?: any;
  logOut?: any;
}

const UserAuthContext = createContext({} as IUserAuthProps);

export const UserAuthContextProvider: FC<IUserAuthProps> = ({ children }) => {
  const [user, setUser] = useState<FBUser>(null);

  function googleSignIn() {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider);
  }

  function userSignUp(email: string, password: string) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function userSignIn(email: string, password: string) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logOut() {
    return signOut(auth);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserAuthContext.Provider
      value={{ user, googleSignIn, userSignUp, userSignIn, logOut }}
    >
      {children}
    </UserAuthContext.Provider>
  );
};

export function useUserAuth() {
  return useContext(UserAuthContext);
}
