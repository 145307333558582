import { Check, Close, Shield } from "@mui/icons-material";
import { ReactComponent as CommentIcon } from "./comment-35.svg";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { nameToInitials, stringToColor } from "../utils/utils";
import { ReactComponent as WayfinderLogo } from "../components/logo_blank.svg";
import { ReactComponent as Group } from "../components/group-hybrid.svg";

interface IResponseCardProps {
  name: string;
  agreement: number;
  confidence: number;
  completedAt?: string;
  comment?: string;
  onClose: any;
}

const ResponseCard = ({
  name,
  agreement,
  confidence,
  completedAt,
  comment,
  onClose,
}: IResponseCardProps) => (
  <Card variant="outlined">
    <CardHeader
      avatar={
        name === "Group Score" ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <WayfinderLogo
              height={48}
              width={48}
              style={{ position: "relative" }}
            />
            <Group
              height={30}
              width={30}
              style={{ position: "absolute", margin: "8px" }}
            />
          </div>
        ) : (
          <Avatar sx={{ bgcolor: stringToColor(name) }}>
            {nameToInitials(name)}
          </Avatar>
        )
      }
      title={name}
      subheader={completedAt != null && new Date(completedAt).toLocaleString()}
      action={
        <IconButton aria-label="close" onClick={onClose}>
          <Close />
        </IconButton>
      }
    />

    <CardContent>
      <Grid container direction={"row"}>
        <Grid item xs={6}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "40px 112px 1fr",
              rowGap: "1rem",
              alignItems: "center",
            }}
          >
            <Check color="success" />
            <Typography component="div">Agreement:</Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {agreement} / 10
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "40px 112px 1fr",
              rowGap: "1rem",
              alignItems: "center",
            }}
          >
            <Shield htmlColor="#0677A1" />
            <Typography component="div">Confidence:</Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {confidence} / 10
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "40px 112px 1fr",
          rowGap: "1rem",
          alignItems: "center",
        }}
        marginTop={3}
      >
        {comment && (
          <>
            <CommentIcon
              className="comment"
              height={20}
              width={20}
              style={{ width: "1.5em", height: "1.35em" }}
            />
            <Typography component="div">Comment:</Typography>
            <Typography variant="body1">{comment}</Typography>
          </>
        )}
      </Box>
    </CardContent>
  </Card>
);

export default ResponseCard;
