import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  PaperProps,
  Slider,
  TextField,
  Typography,
} from "@mui/material";

import ResponseCard from "./ResponseCard";

import Canvas from "./Canvas";
import { useSurveyResults } from "../context/results.context";
import { ShieldThumbComponent } from "./ShieldThumbComponent";
import { stringToColor } from "../utils/utils";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { SurveyDataService } from "../services/survey.service";
import { IScore } from "../types/survey.type";
import Draggable from "react-draggable";

interface IResultQuestionProps {
  questionNumber: number;
  questionName: string;
  questionText: string;
  responses: Array<any> | undefined;
  surveyId: string;
  scores: IScore;
}

type DataPoint = {
  id: string;
  name: string;
  x: number;
  y: number;
  comment?: string;
  fill: string;
};

type GroupScoreValues = {
  agreement: number;
  confidence: number;
  comment: string;
};

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const ResultQuestion = ({
  questionNumber,
  questionName,
  questionText,
  responses,
  surveyId,
  scores,
}: IResultQuestionProps) => {
  const [scoreMode, setScoreMode] = useState<boolean>(false);
  const { selectedParticipant, selectParticipant } = useSurveyResults();

  const surveyService = new SurveyDataService();

  const methods = useForm<GroupScoreValues>({
    defaultValues: {
      agreement: scores[`q${questionNumber}_agree`] ?? 5,
      confidence: scores[`q${questionNumber}_confidence`] ?? 5,
      comment: scores[`q${questionNumber}_comment`] ?? "",
    },
  });

  const scoreDialogClose = () => {
    methods.reset({
      agreement: scores[`q${questionNumber}_agree`] ?? 5,
      confidence: scores[`q${questionNumber}_confidence`] ?? 5,
      comment: scores[`q${questionNumber}_comment`] ?? "",
    });

    setScoreMode(false);
  };

  const onSubmitScores: SubmitHandler<GroupScoreValues> = async ({
    agreement,
    confidence,
    comment,
  }): Promise<void> => {
    surveyService.setGroupScore(
      surveyId,
      questionNumber,
      agreement,
      confidence,
      comment
    );
    methods.reset({
      agreement: agreement,
      confidence: confidence,
      comment: comment,
    });
    setScoreMode(false);
  };

  const onRemoveScores = (): void => {
    surveyService.removeGroupScore(surveyId, questionNumber);
    methods.reset({
      agreement: scores[`q${questionNumber}_agree`] ?? 5,
      confidence: scores[`q${questionNumber}_confidence`] ?? 5,
      comment: scores[`q${questionNumber}_comment`] ?? "",
    });
    // setScoreMode(false);
  };

  const scoreDialogOpen = () => {
    setScoreMode(true);
  };

  // Parse responses to data
  const responseData = [] as Array<DataPoint>;

  responses?.forEach((response, i) => {
    responseData.push({
      id: response.id,
      name: response.name,
      x: response.data[`${questionName}_agree`],
      y: response.data[`${questionName}_confidence`],
      comment: response.data[`${questionName}_comment`],
      fill: stringToColor(response.name),
    });
  });

  responseData.push({
    id: "group",
    name: "Group",
    x: scores[`q${questionNumber}_agree`],
    y: scores[`q${questionNumber}_confidence`],
    comment: scores[`q${questionNumber}_comment`],
    fill: stringToColor("group"),
  });

  const questionScore = {
    x: scores[`q${questionNumber}_agree`],
    y: scores[`q${questionNumber}_confidence`],
  };

  const GetResponseCard = () => {
    // Get the Response data from the Participant ID
    let response = responses?.find((r) => r.id === selectedParticipant);

    // Check if this is the group score, if so, display diff card (TODO: Refactor DRY)
    if (response == null && selectedParticipant === "group") {
      return (
        <Container maxWidth="md">
          <ResponseCard
            name={"Group Score"}
            agreement={scores[`q${questionNumber}_agree`]}
            confidence={scores[`q${questionNumber}_confidence`]}
            comment={scores[`${questionName}_comment`]}
            onClose={() => selectParticipant("")}
          />
        </Container>
      );
    }

    return (
      <Container maxWidth="md">
        <ResponseCard
          name={response.name}
          agreement={response.data[`${questionName}_agree`]}
          confidence={response.data[`${questionName}_confidence`]}
          comment={response.data[`${questionName}_comment`]}
          completedAt={response.completedAt?.toDate()}
          onClose={() => selectParticipant("")}
        />
      </Container>
    );
  };

  return (
    <Paper
      variant="outlined"
      sx={{ my: 3, p: { xs: 2, md: 3 } }}
      id={`q${questionNumber}`}
    >
      <Grid container>
        <Grid item xs={9}>
          <Typography component="div" variant="h6" gutterBottom sx={{ mb: 5 }}>
            {questionNumber}. {questionText}
          </Typography>
        </Grid>
        <Grid item xs={3} textAlign="right">
          <Button variant="outlined" onClick={scoreDialogOpen}>
            Assign Group Score
          </Button>
        </Grid>
      </Grid>

      <Canvas initWidth={600} data={responseData} score={questionScore} />

      {selectedParticipant!.length > 1 && <GetResponseCard />}

      <Dialog
        open={scoreMode}
        onClose={scoreDialogClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle id="draggable-dialog-title">
          Group Consensus Score
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 3 }}>
            Set an agreement and confidence score as a group.
          </DialogContentText>
          <Typography gutterBottom>Agreement</Typography>
          <Controller
            name="agreement"
            control={methods.control}
            render={({ field }) => (
              <Slider
                color="secondary"
                min={0}
                max={10}
                valueLabelDisplay="off"
                marks={sliderMarks}
                track={false}
                sx={{
                  mb: 4,
                  ".MuiSlider-rail": { color: "#bfbfbf" },
                  ".MuiSlider-mark": { display: "none" },
                }}
                {...field}
              />
            )}
          />

          <Typography gutterBottom>Level of confidence</Typography>
          <Controller
            name="confidence"
            control={methods.control}
            render={({ field }) => (
              <Slider
                color="primary"
                min={0}
                max={10}
                valueLabelDisplay="off"
                marks={sliderMarks}
                track={false}
                components={{ Thumb: ShieldThumbComponent }}
                sx={{
                  mb: 4,
                  ".MuiSlider-rail": { color: "#0677A1" },
                  ".MuiSlider-mark": { display: "none" },
                  ".MuiSlider-thumb": { backgroundColor: "transparent" },
                }}
                {...field}
              />
            )}
          />

          <Typography gutterBottom>Comment(s)</Typography>
          <Controller
            name="comment"
            control={methods.control}
            render={({ field }) => (
              <TextField
                fullWidth
                variant="outlined"
                multiline
                rows={5}
                {...field}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={onRemoveScores}>
            Remove Group Score
          </Button>
          <Button variant="outlined" onClick={scoreDialogClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => methods.handleSubmit(onSubmitScores)()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

const sliderMarks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 6,
    label: "6",
  },
  {
    value: 7,
    label: "7",
  },
  {
    value: 8,
    label: "8",
  },
  {
    value: 9,
    label: "9",
  },
  {
    value: 10,
    label: "10",
  },
];

export default ResultQuestion;
