import React, { useEffect, useMemo, useState } from "react";
import {
  DocumentData,
  FirestoreError,
  QuerySnapshot,
} from "firebase/firestore";

import { useParams } from "react-router-dom";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";

import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import IResponse from "../types/response.type";
import ISurvey, { IQuestion, SurveyFormData } from "../types/survey.type";
import { SurveyDataService } from "../services/survey.service";
import ManageEvaluators from "../components/ManageEvaluators";
import { formatRelative } from "date-fns";
import ManageQuestions from "../components/ManageQuestions";
import { useUserAuth } from "../context/auth.context";

interface ISnackbar {
  open: boolean;
  message: string;
  severity: "success" | "error" | "warning" | "info";
}

export interface IEditSurveyPageProps {}

const EditSurveyPage: React.FunctionComponent<IEditSurveyPageProps> = (
  props
) => {
  const [survey, setSurvey] = useState<ISurvey | undefined>();
  const [responses, setResponses] = useState<IResponse[] | undefined>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [snackbar, setSnackbar] = useState<ISnackbar>();

  const surveyService = new SurveyDataService();
  const { surveyId } = useParams();
  const { user } = useUserAuth();

  const methods = useForm<SurveyFormData>({
    defaultValues: useMemo(() => {
      return {
        title: survey?.title,
        description: survey?.data.description,
        published: survey?.published,
        questions: survey?.data.pages.map((q: IQuestion) => {
          return { text: q.description };
        }),
      };
    }, [survey]),
  });

  useEffect(() => {
    const unsubSurvey = surveyService.streamSurvey(
      surveyId!,
      (doc: DocumentData) => {
        setSurvey(doc.data());
      },
      (error: FirestoreError) => console.error(error)
    );

    const unsubResponses = surveyService.streamResponses(
      surveyId!,
      user!.uid,
      (snap: QuerySnapshot<DocumentData>) => {
        const responses = snap.docs.map((doc) => doc.data() as IResponse);
        setResponses(responses);
      },
      (error: FirestoreError) => {
        console.error(error);
      }
    );

    setLoading(false);

    return () => {
      unsubSurvey();
      unsubResponses();
    };
  }, []);

  useEffect(() => {
    methods.reset({
      title: survey?.title,
      description: survey?.data.description,
      published: survey?.published,
      questions: survey?.data.pages.map((q: IQuestion) => {
        return { text: q.description };
      }),
    });
  }, [survey]);

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    setSnackbar({ open: false, message: "", severity: "success" });
  };

  const handlePublishSurvey = async () => {
    await surveyService.publishSurvey(surveyId!);

    // Edit successful, build and show a snackbar
    const snack: ISnackbar = {
      open: true,
      message: "Survey has been successfully published",
      severity: "success",
    };

    setSnackbar(snack);
  };

  const onSubmitEdits: SubmitHandler<SurveyFormData> = async (data) => {
    const { title, description, questions } = data;
    await surveyService.editSurvey(survey!.id, title, description, questions);

    // Edit successful, build and show a snackbar
    const snack: ISnackbar = {
      open: true,
      message: "Survey updates have been successfully saved",
      severity: "success",
    };

    setSnackbar(snack);
  };

  if (loading || !survey) return <CircularProgress />;

  return (
    <Container component="main" maxWidth="md" sx={{ py: { xs: 1, md: 2 } }}>
      <Typography component="h2" variant="h4" align="center" gutterBottom>
        Edit Survey
      </Typography>

      <FormProvider {...methods}>
        <Grid container spacing={2}>
          {/* Survey Summary */}

          <Grid item xs={12}>
            <form onSubmit={methods.handleSubmit(onSubmitEdits)}>
              <Paper variant="outlined" sx={{ p: { xs: 2, md: 3 } }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="h5">Summary</Typography>
                  {survey.published ? (
                    <Chip
                      label={`Published ${formatRelative(
                        survey.publishedAt!.toDate(),
                        Date.now()
                      )}`}
                      color="success"
                      size="medium"
                    />
                  ) : (
                    <Chip
                      label={`Draft started ${formatRelative(
                        survey.createdAt.toDate(),
                        Date.now()
                      )}`}
                      variant="outlined"
                      size="medium"
                    />
                  )}
                </Box>
                <Grid container spacing={2} direction="column" sx={{ my: 1 }}>
                  <Grid item xs={12}>
                    <Controller
                      name="title"
                      control={methods.control}
                      render={({ field }) => (
                        <TextField
                          label="Survey Title"
                          fullWidth
                          variant="outlined"
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="description"
                      control={methods.control}
                      render={({ field }) => (
                        <TextField
                          label="Survey Description"
                          fullWidth
                          variant="outlined"
                          multiline
                          rows={5}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </form>
          </Grid>

          {/* Evaluators */}
          <Grid item xs={12}>
            <ManageEvaluators
              survey={survey}
              responses={responses}
              surveyService={surveyService}
            />
          </Grid>

          {/* Statements */}
          <Grid item xs={12}>
            <form onSubmit={methods.handleSubmit(onSubmitEdits)}>
              <ManageQuestions hasResponses={survey.countCompleted > 0} />
            </form>
          </Grid>
        </Grid>
      </FormProvider>

      <Grid container spacing={3} sx={{ mb: 6 }}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => methods.handleSubmit(onSubmitEdits)()}
          >
            {survey.published ? "Save Changes" : "Save Draft"}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={survey.published}
            onClick={() => handlePublishSurvey()}
            sx={survey.published ? { ml: 2, display: "none" } : { ml: 2 }}
          >
            Publish Survey
          </Button>
        </Grid>
      </Grid>

      <Snackbar
        open={snackbar?.open}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>
    </Container>
  );
};

export default EditSurveyPage;
