import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import { useUserAuth } from "../context/auth.context";
import ISurvey from "../types/survey.type";
import { SurveyDataService } from "../services/survey.service";
import {
  DocumentData,
  FirestoreError,
  QuerySnapshot,
} from "firebase/firestore";
import {
  Send as SendIcon,
  ContentCopy as ContentCopyIcon,
  Delete as DeleteIcon,
  PictureAsPdf as PictureAsPdfIcon,
} from "@mui/icons-material";
import { formatRelative } from "date-fns";
import { AlertDialog } from "../components/AlertDialog";
import surveyTemplates from "../config/templates";

export interface IDashboardPageProps {}

const DashboardPage: FC<IDashboardPageProps> = (props) => {
  const [surveyList, setSurveyList] = useState<ISurvey[]>([]);
  const [deleteDialog, setDeleteDialog] = useState<string>("");
  const [duplicateDialog, setDuplicateDialog] = useState<string>("");
  const [publishDialog, setPublishDialog] = useState<string>("");
  const [error, setError] = useState<string>();

  const surveyService = new SurveyDataService();

  const { user } = useUserAuth();
  const navigate = useNavigate();

  useEffect(() => {
    return surveyService.streamSurveysForUser(
      user!.uid,
      (snap: QuerySnapshot<DocumentData>) => {
        const surveys = snap.docs.map((doc) => doc.data() as ISurvey);
        setSurveyList(surveys);
      },
      (error: FirestoreError) => {
        console.error(error);
        console.log("Error with user: ", user!.uid);
        setError("survey-get-fail");
      }
    );
  }, []);

  const drafts = surveyList.filter((survey) => !survey.published);
  const published = surveyList.filter(
    (survey) =>
      survey.published &&
      (survey.countPending === null || survey.countPending > 0)
  );
  const completed = surveyList.filter((survey) => {
    return (
      survey.published &&
      (survey.countPending === null ||
        (survey.countPending === 0 && survey.countCompleted > 0))
    );
  });

  return (
    <Container component="main" maxWidth="md" sx={{ py: { xs: 1, md: 2 } }}>
      <Grid container spacing={2} sx={{ my: { xs: 1, md: 2 } }}>
        <Grid item sm={12} sx={{ textAlign: "center", mb: 4 }}>
          <Typography component="h2" variant="h4" align="center" gutterBottom>
            Create a survey
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate("/dashboard/create")}
          >
            Create New Survey
          </Button>
        </Grid>
        <Grid item sm={12}>
          <Paper variant="outlined" sx={{ p: { xs: 2, md: 3 } }}>
            <Typography component="h1" variant="h5">
              My Surveys
            </Typography>

            <List>
              {error === "survey-get-fail" ? (
                <Typography variant="body2">
                  Failed to fetch surveys.
                </Typography>
              ) : (
                <>
                  <ListSubheader sx={{ mt: 3 }}>Drafts</ListSubheader>
                  {drafts.length > 0 ? (
                    drafts.map((survey: ISurvey, i: number) => (
                      <Box key={i}>
                        <ListItem disablePadding>
                          <ListItemButton
                            onClick={() => navigate(`edit/${survey.id}`)}
                          >
                            <ListItemText
                              primary={survey.title}
                              secondary={
                                "Created " +
                                formatRelative(
                                  survey.createdAt.toDate(),
                                  Date.now()
                                )
                              }
                            />
                          </ListItemButton>
                          <ListItemIcon>
                            <Tooltip title="Duplicate">
                              <IconButton
                                aria-label="duplicate"
                                onClick={() => setDuplicateDialog(survey.id)}
                              >
                                <ContentCopyIcon />
                              </IconButton>
                            </Tooltip>
                          </ListItemIcon>
                          <ListItemIcon>
                            <Tooltip title="Delete">
                              <IconButton
                                aria-label="delete"
                                onClick={() => setDeleteDialog(survey.id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </ListItemIcon>
                          <ListItemIcon>
                            <Tooltip title="Publish">
                              <IconButton
                                edge="end"
                                aria-label="publish"
                                onClick={() => setPublishDialog(survey.id)}
                              >
                                <SendIcon />
                              </IconButton>
                            </Tooltip>
                          </ListItemIcon>
                        </ListItem>
                      </Box>
                    ))
                  ) : (
                    <Typography variant="body2" textAlign="center">
                      No drafts.
                    </Typography>
                  )}
                  <ListSubheader sx={{ mt: 3 }}>
                    Published / In Progress
                  </ListSubheader>
                  {published.length > 0 ? (
                    published.map((survey: ISurvey, i: number) => (
                      <Box key={i}>
                        <ListItem disablePadding>
                          <ListItemButton
                            onClick={() => navigate(`results/${survey.id}`)}
                          >
                            <Stack>
                              <ListItemText
                                primary={survey.title}
                                secondary={
                                  "Published " +
                                  formatRelative(
                                    survey.publishedAt!.toDate(),
                                    Date.now()
                                  )
                                }
                              />
                              {survey?.countPending != null &&
                                survey?.countCompleted != null && (
                                  <Chip
                                    color="info"
                                    label={`${survey.countCompleted} / ${
                                      survey.countPending +
                                      survey.countCompleted
                                    } evaluations`}
                                    size="small"
                                  />
                                )}
                            </Stack>
                          </ListItemButton>
                          <ListItemIcon>
                            <Tooltip title="Duplicate">
                              <IconButton
                                aria-label="duplicate"
                                onClick={() => setDuplicateDialog(survey.id)}
                              >
                                <ContentCopyIcon />
                              </IconButton>
                            </Tooltip>
                          </ListItemIcon>

                          <ListItemIcon>
                            <Tooltip title="Delete">
                              <IconButton
                                aria-label="delete"
                                onClick={() => setDeleteDialog(survey.id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </ListItemIcon>

                          <ListItemIcon>
                            <Tooltip title="Export as PDF">
                              <IconButton
                                edge="end"
                                aria-label="export"
                                onClick={() => {
                                  navigate(`export/${survey.id}`);
                                }}
                              >
                                <PictureAsPdfIcon />
                              </IconButton>
                            </Tooltip>
                          </ListItemIcon>
                        </ListItem>
                      </Box>
                    ))
                  ) : (
                    <Typography variant="body2" textAlign="center">
                      No published surveys.
                    </Typography>
                  )}

                  <ListSubheader sx={{ mt: 3 }}>Completed</ListSubheader>
                  {completed.length > 0 ? (
                    completed.map((survey: ISurvey, i: number) => (
                      <Box key={i}>
                        <ListItem disablePadding>
                          <ListItemButton
                            onClick={() => navigate(`results/${survey.id}`)}
                          >
                            <Stack>
                              <ListItemText
                                primary={survey.title}
                                secondary={
                                  "Published " +
                                  formatRelative(
                                    survey.publishedAt!.toDate(),
                                    Date.now()
                                  )
                                }
                              />
                              {survey?.countPending != null &&
                                survey?.countCompleted != null && (
                                  <Chip
                                    color="success"
                                    label={`${survey.countCompleted} / ${
                                      survey.countPending +
                                      survey.countCompleted
                                    } evaluations`}
                                    size="small"
                                  />
                                )}
                            </Stack>
                          </ListItemButton>
                          <ListItemIcon>
                            <Tooltip title="Duplicate">
                              <IconButton
                                aria-label="duplicate"
                                onClick={() => setDuplicateDialog(survey.id)}
                              >
                                <ContentCopyIcon />
                              </IconButton>
                            </Tooltip>
                          </ListItemIcon>

                          <ListItemIcon>
                            <Tooltip title="Delete">
                              <IconButton
                                aria-label="delete"
                                onClick={() => setDeleteDialog(survey.id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </ListItemIcon>

                          <ListItemIcon>
                            <Tooltip title="Export as PDF">
                              <IconButton
                                edge="end"
                                aria-label="export"
                                onClick={() => {
                                  navigate(`export/${survey.id}`);
                                }}
                              >
                                <PictureAsPdfIcon />
                              </IconButton>
                            </Tooltip>
                          </ListItemIcon>
                        </ListItem>
                      </Box>
                    ))
                  ) : (
                    <Typography variant="body2" textAlign="center">
                      No completed surveys. Assign group scores to complete a
                      survey.
                    </Typography>
                  )}
                </>
              )}
            </List>
          </Paper>
        </Grid>
        <Grid item sm={12} sx={{ mt: 4, mb: 4 }}>
          <Typography
            component="h3"
            variant="h5"
            align="center"
            gutterBottom
            mb={2}
          >
            Get started with a template
          </Typography>
          <Grid container spacing={1}>
            {Object.values(surveyTemplates).map((template, index) => {
              return (
                <Grid item key={index} xs={12} sm={4}>
                  <Card
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                    }}
                  >
                    <CardContent>
                      <Typography variant="h6" component="div">
                        {template.defaultValues.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ mb: 1.5 }}
                        color="text.secondary"
                      >
                        by Strategic Wayfinding Team
                      </Typography>
                      <Typography variant="body2">
                        {template.defaultValues.description}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        size="small"
                        onClick={() =>
                          navigate("/dashboard/create", {
                            state: template.defaultValues,
                          })
                        }
                      >
                        Use Template
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <AlertDialog
          title="Delete this Survey?"
          text="This action cannot be undone and all Survey, Statements, and Evaluator data will be lost forever."
          confirmButton="Delete Forever"
          open={deleteDialog.length > 1}
          onClose={async (val) => {
            // Close the dialog
            setDeleteDialog("");

            // If user accepted, remove the response
            if (val) {
              await surveyService.removeSurvey(deleteDialog);
            }
          }}
        />

        <AlertDialog
          title="Duplicate this Survey?"
          text="This action will duplicate the title, description, and Statements. Evaluators will not be copied."
          confirmButton="Duplicate"
          open={duplicateDialog.length > 1}
          onClose={async (val) => {
            // Close the dialog
            setDuplicateDialog("");

            // If user accepted, remove the response
            if (val) {
              await surveyService.duplicateSurvey(duplicateDialog, user!.uid);
            }
          }}
        />

        <AlertDialog
          title="Publish Survey"
          text="Are you sure you're ready to publish this Survey?"
          confirmButton="Publish"
          open={publishDialog.length > 1}
          onClose={async (val) => {
            // Close the dialog
            setPublishDialog("");

            // If user accepted, create the survey
            if (val) {
              await surveyService.publishSurvey(publishDialog);
            }
          }}
        />
      </Grid>
    </Container>
  );
};

export default DashboardPage;
