import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface IAlertDialogProps {
  title?: string;
  text: string;
  closeButton?: string;
  confirmButton?: string;
  open: boolean;
  onClose: (value: boolean) => void;
}

export function AlertDialog(props: IAlertDialogProps) {
  const { title, text, closeButton, confirmButton, onClose, open } = props;

  const handleClose = (value: boolean) => {
    onClose(value);
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.35)" } }}
    >
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => handleClose(false)}>
          {closeButton ?? "Cancel"}
        </Button>
        <Button variant="contained" onClick={() => handleClose(true)} autoFocus>
          {confirmButton ?? "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
