import {
  Avatar,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import Canvas from "./Canvas";
import { nameToInitials, stringToColor } from "../utils/utils";
import { IScore } from "../types/survey.type";
import { ReactComponent as WayfinderLogo } from "../components/logo_blank.svg";
import { ReactComponent as Group } from "../components/group-hybrid.svg";

interface IExportQuestionProps {
  questionNumber: number;
  questionName: string;
  questionText: string;
  responses: Array<any> | undefined;
  surveyId: string;
  scores: IScore;
}

type DataPoint = {
  id: string;
  name: string;
  x: number;
  y: number;
  comment?: string;
  fill: string;
};

const ExportQuestion = ({
  questionNumber,
  questionName,
  questionText,
  responses,
  surveyId,
  scores,
}: IExportQuestionProps) => {
  // Parse responses to data
  const responseData = [] as Array<DataPoint>;

  responses?.forEach((response, i) => {
    responseData.push({
      id: response.id,
      name: response.name,
      x: response.data[`${questionName}_agree`],
      y: response.data[`${questionName}_confidence`],
      comment: response.data[`${questionName}_comment`],
      fill: stringToColor(response.name),
    });
  });

  responseData.push({
    id: "group",
    name: "Group",
    x: scores[`q${questionNumber}_agree`],
    y: scores[`q${questionNumber}_confidence`],
    comment: scores[`q${questionNumber}_comment`],
    fill: stringToColor("group"),
  });

  const questionScore = {
    x: scores[`q${questionNumber}_agree`],
    y: scores[`q${questionNumber}_confidence`],
  };

  return (
    <Paper variant="outlined" sx={{ my: 3, p: { xs: 2, md: 3 } }}>
      <Grid container>
        <Grid item xs={9}>
          <Typography component="div" variant="h6" gutterBottom sx={{ mb: 5 }}>
            {questionNumber}. {questionText}
          </Typography>
        </Grid>
      </Grid>

      <Canvas initWidth={600} data={responseData} score={questionScore} />

      <TableContainer component={Paper}>
        <Table aria-label="simple table" sx={{ tableLayout: "auto" }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: 100, maxWidth: 100 }}>
                Evaluator
              </TableCell>
              <TableCell align="center" style={{ minWidth: 50, maxWidth: 50 }}>
                Agreement
              </TableCell>
              <TableCell align="center" style={{ minWidth: 50, maxWidth: 50 }}>
                Confidence
              </TableCell>
              <TableCell align="left" style={{ minWidth: 350, maxWidth: 350 }}>
                Comment
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {responseData.map((row) => {
              if (!row.x) return <></>;

              return (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ minWidth: 100, maxWidth: 100 }}
                  >
                    {row.name === "Group" ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <WayfinderLogo
                            height={42}
                            width={42}
                            style={{ position: "relative" }}
                          />
                          <Group
                            height={24}
                            width={24}
                            style={{ position: "absolute", margin: "9px" }}
                          />
                        </div>
                        <Typography fontWeight="bold">
                          {"Group Score"}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Avatar
                          sx={{
                            bgcolor: stringToColor(row.name),
                          }}
                        >
                          {nameToInitials(row.name)}
                        </Avatar>
                        <Typography>{row.name}</Typography>
                      </>
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ minWidth: 75, maxWidth: 75 }}
                  >
                    {row.x}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ minWidth: 75, maxWidth: 75 }}
                  >
                    {row.y}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      minWidth: 300,
                      maxWidth: 300,
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    }}
                  >
                    {row.comment}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ExportQuestion;
