import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Components
import ManageQuestions from "../components/ManageQuestions";
import AddEvaluators from "../components/AddEvaluators";

// Styles
import "./CreateSurvey.css";
import { useUserAuth } from "../context/auth.context";
import { SurveyFormData } from "../types/survey.type";
import { SurveyDataService } from "../services/survey.service";
import { AlertDialog } from "../components/AlertDialog";

export interface ICreateSurveyProps {}

const CreateSurveyPage: FC<ICreateSurveyProps> = (props) => {
  const { user } = useUserAuth();
  const navigate = useNavigate();

  // Default Survey Data?
  const location = useLocation();
  const surveyTemplate = (location.state as SurveyFormData) || undefined;

  // State
  const [publishDialog, setPublishDialog] = useState<string>("");

  // Services
  const surveyService = new SurveyDataService();

  // Validation Schemas
  const participantSchema = {
    name: yup
      .string()
      .max(80, "Name must be less than 80 characters")
      .required("Please enter full name"),
    email: yup
      .string()
      .max(250, "Email must be less than 250 characters")
      .email("Please enter a valid email"),
  };

  const questionSchema = {
    text: yup
      .string()
      .max(999, "Statement must be less than 999 characters")
      .required("Please enter a statement"),
  };

  const schema = yup.object().shape({
    participants: yup
      .array()
      .of(yup.object().shape(participantSchema))
      .required(),
    questions: yup
      .array()
      .of(yup.object().shape(questionSchema))
      .min(1, "Must have at leaset 1 statement")
      .required(),
  });

  // Set the default survey values
  let defaultSurveyValues: SurveyFormData = {
    title: "",
    description: "",
    published: false,
    questions: [{ id: 1, text: "" }],
    participants: [{ id: 1, name: "", email: "" }],
  };
  // If we started from a template, override default survey values
  if (surveyTemplate) {
    defaultSurveyValues = surveyTemplate;
  }

  const methods = useForm<SurveyFormData>({
    defaultValues: defaultSurveyValues,
    resolver: yupResolver(schema),
  });

  const addSurvey: SubmitHandler<SurveyFormData> = async (
    data
  ): Promise<void> => {
    const surveyId = await surveyService.addSurvey(data, user!.uid);
    methods.reset();
    setPublishDialog(surveyId);
  };

  return (
    <Container component="main" maxWidth="md" sx={{ py: { xs: 1, md: 2 } }}>
      <Typography component="h2" variant="h4" align="center" gutterBottom>
        Create Survey
      </Typography>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(addSurvey)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper variant="outlined" sx={{ p: { xs: 2, md: 3 } }}>
                <Typography variant="h5">Summary</Typography>
                <Grid container spacing={2} direction="column" sx={{ my: 1 }}>
                  <Grid item xs={12}>
                    <Controller
                      name="title"
                      control={methods.control}
                      render={({ field }) => (
                        <TextField
                          label="Survey Title"
                          fullWidth
                          variant="outlined"
                          autoComplete="off"
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="description"
                      control={methods.control}
                      render={({ field }) => (
                        <TextField
                          label="Survey Description"
                          fullWidth
                          variant="outlined"
                          autoComplete="off"
                          multiline
                          rows={5}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <AddEvaluators />
            </Grid>
            <Grid item xs={12}>
              <ManageQuestions />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => methods.handleSubmit(addSurvey)()}
            >
              Create Survey
            </Button>
          </Box>
        </form>
      </FormProvider>

      <AlertDialog
        title="Survey Created"
        text="Your survey has been created successfully. Publish the survey now or continue editing as a draft?"
        closeButton="Save as Draft"
        confirmButton="Publish Survey"
        open={publishDialog !== ""}
        onClose={async (val) => {
          // If user confirms, then publish survey
          if (val) {
            await surveyService.publishSurvey(publishDialog);
          }

          // Close the dialog
          setPublishDialog("");
          navigate(`/dashboard/edit/${publishDialog}`);
        }}
      />
    </Container>
  );
};

export default CreateSurveyPage;
