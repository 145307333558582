import { scaleOrdinal } from "d3-scale";
import { schemeTableau10 } from "d3-scale-chromatic";

// TODO: Deprecated function, use nameToInitials
export const getInitials = (name: string) => {
  let names = name.split(/[- ]/);
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }

  return initials;
};

export const nameToInitials = (name: string) => {
  return name
    .split(/[- ]/)
    .map((name) => name[0])
    .join("")
    .toUpperCase();
};

export function stringToColor(string: string) {
  var colors = [
    "#59253A",
    "#78244C",
    "#8C4D62",
    "#0677A1",
    "#2D4159",
    "#BF612A", //orange
    "#F2B279", // light orange
    "#163A59", // dark blue
  ];

  var hash = 0;
  if (string.length === 0) return "#d3d3d3";
  for (var i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  hash = ((hash % colors.length) + colors.length) % colors.length;
  return colors[hash];
}

export const stringAvatar = (name: string) => {
  const initials = nameToInitials(name);

  return {
    sx: {
      bgcolor: stringToColor(name),
      fontSize: initials.length > 2 ? "0.9rem" : null,
    },
    children: initials,
  };
};

export const getColor = () => {
  scaleOrdinal(schemeTableau10).range();
};
